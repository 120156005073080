<template>
  <div>
    <TheTeCommonHeaderSimple />
    <main class="pt-[4.375rem]">
      <slot />
    </main>
    <TheTeCommonFooter>
      <template #top-menu>
        <ul class="te-footer-top mb-2 flex flex-wrap">
          <li class="mb-2 mr-6">
            <a
              href="/course"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >コース紹介</a>
          </li><li class="mb-2 mr-6">
            <a
              href="/price"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >料金プラン</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
              href="https://support.techacademy.jp/hc/ja"
            >よくあるご質問</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              href="/contest"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >コンテスト</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              href="/blog"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >受講生インタビュー</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              href="/job"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >メンター募集</a>
          </li>
          <li class="mb-2">
            <a
              href="/magazine/"
              target="_blank"
              class="block p-0 pr-3 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >マガジン<i
              class="fa fa-external-link pl-2"
              aria-hidden="true"
            />
            </a>
          </li>
        </ul>
      </template>
    </TheTeCommonFooter>
  </div>
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: {
    "data-theme": 'techacademy',
  },
})
</script>

<script lang="ts">
import { defineNuxtComponent } from '#app'
import TheTeCommonHeaderSimple from '~/components/Te/TeCommon/Header/TheTeCommonHeaderSimple/TheTeCommonHeaderSimple.vue'
import TheTeCommonFooter from '~/components/Te/TeCommon/Footer/TheTeCommonFooter/TheTeCommonFooter.vue'

const options = {
  components: {
    TheTeCommonHeaderSimple,
    TheTeCommonFooter,
  },
}

export default defineNuxtComponent(options)
</script>
